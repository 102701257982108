import clsx from "clsx"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import useDevice from "../../hooks/useDeviceMedia"
import useHasScrolled from "../../hooks/useHasScrolled"
import logoWhite from "../../images/icons/logo_white.svg"
import { getWhatsAppURL } from "../../site/utils"
import { GetPageURL } from "../common/site/utils"
import BurgerMenu from "./BurgerMenu"
import "./Header.scss"
import SearchOverlay from "./SearchOverlay"

const Header = ({ layout }) => {
  const data = useStaticQuery(graphql`
    query GetHeaderData {
      glstrapi {
        siteConfig {
          phone
        }
        socialLink {
          left_menu {
            id
            menu_items {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            sub_menu_item {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }
          right_menu {
            id
            menu_items {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            sub_menu_item {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const leftMenus = data.glstrapi.socialLink.left_menu
  const rightMenus = data.glstrapi.socialLink.right_menu
  const phoneNumber = data.glstrapi.siteConfig.phone

  const { isLargeScreen } = useDevice()

  const scrolled = useHasScrolled()

  const hasWhiteBG =
    scrolled ||
    layout === "contact_page_template" ||
    layout === "search-template" ||
    layout === "property-details-template" ||
    layout === "news-details-template" ||
    layout === "area-details-template" ||
    layout === "static_template" ||
    layout === "404"

  const Menus = ({ menus }) => (
    <>
      {menus?.length > 0 &&
        menus.map(menu => {
          const href =
            menu.menu_items?.custom_link === "javascript:void(0)"
              ? { href: menu.menu_items?.custom_link }
              : {
                  to: GetPageURL(
                    menu.menu_items?.menu_item,
                    menu.menu_items?.custom_link
                  ),
                }
          return (
            <div className="menu-dropdown">
              <Link
                {...href}
                className="nav-link"
                target={
                  menu.menu_items?.target === "new_window" ? "_blank" : ""
                }
              >
                {menu.menu_items?.label}
              </Link>
              {menu.sub_menu_item?.length > 0 && (
                <div className="sub-menu-section-wrap">
                  <div className="sub-menu-section">
                    {menu.sub_menu_item.map(menuItem => (
                      <Link
                        to={GetPageURL(
                          menuItem?.menu_item,
                          menuItem?.custom_link
                        )}
                        className={clsx("sub-menu-item")}
                        target={
                          menuItem?.target === "new_window" ? "_blank" : ""
                        }
                      >
                        {menuItem?.label}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )
        })}
    </>
  )

  // Search overlay modal
  const [showSearchOverlay, setShowSearchOverlay] = useState(false)

  const handleCloseSearchOverlay = () => setShowSearchOverlay(false)
  const handleShowSearchOverlay = () => setShowSearchOverlay(true)
  // Search overlay modal

  // Google tag (gtag.js) event - delayed navigation helper
  React.useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `function gtagSendEvent(url){var callback=function(){if(typeof url==='string'){window.location=url;}};gtag('event','conversion_event_submit_lead_form',{'event_callback':callback,'event_timeout':2000,});return false;}`;
    document.head.appendChild(script);
    return () => document.head.removeChild(script);
  }, []);

  return (
    <div
      className={clsx(
        `header-wrap`,
        {
          "header-white-bg": hasWhiteBG,
          "search-template": layout === "search-template",
        },
        { "header-scrolled": scrolled }
      )}
    >
      <div className="header-container">
        <div className="header-left">
          {isLargeScreen && <BurgerMenu />}
          <a href="javascript:void(0)" onClick={handleShowSearchOverlay}>
            <i className="icon icon-search-white"></i>
          </a>
          {!isLargeScreen && <Menus menus={leftMenus} />}
        </div>
        <Link to="/" className="header-center">
          <img src={logoWhite} alt="logo-white" className="logo-img" />
        </Link>
        <div className="header-right">
          {!isLargeScreen && <Menus menus={rightMenus} />}
          <a
            href={getWhatsAppURL(phoneNumber)}
            className="icon icon-whatsapp-white"
            target={"_blank"}
          ></a>
          {isLargeScreen && (
            <a
              href={`tel:${phoneNumber}`}
              className="icon icon-phone-white"
            ></a>
          )}
        </div>
      </div>

      {/* Burger menu */}
      {/* <Offcanvas show={burgerMenushow} onHide={handleBurgerMenuClose} className="header-burger-menu">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas> */}
      {/* Burger menu */}

      {/* Search overlay */}
      <Modal
        show={showSearchOverlay}
        onHide={handleCloseSearchOverlay}
        size="lg"
        className="search-ovelay"
      >
        <Modal.Header closeButton className="d-xl-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <SearchOverlay showOverlay={handleCloseSearchOverlay} />
        </Modal.Body>
      </Modal>
      {/* Search overlay */}
    </div>
  )
}

export default Header
