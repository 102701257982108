import React, { useState } from "react"
import { Offcanvas } from 'react-bootstrap';
import MenuData from "./MenuData";
import "./Header.scss"

const BurgerMenu = () => {
    // 
    const [burgerMenushow, setBurgerMenuShow] = useState(false);

    const handleBurgerMenuClose = () => setBurgerMenuShow(false);
    const handleBurgerMenuShow = () => setBurgerMenuShow(true);
    //

    return (
        <React.Fragment>
            <a href="javascript:void(0)" onClick={handleBurgerMenuShow}><i className="icon icon-burger-white"></i></a>
            <Offcanvas show={burgerMenushow} onHide={handleBurgerMenuClose} className="header-burger-menu">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <MenuData />
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}

export default BurgerMenu