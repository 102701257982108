import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { postFormData } from "./api/Api"
import _ from "lodash"
import { SendMail } from "../common/site/utils"
import Recaptcha from "./inputs/Recaptcha"
import { newsLetterSchema } from "./schemas"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import { formTracking } from "../../site/utils"

const NewsletterForm = () => {
  const recaptchaRef = useRef()
  const [mailSent, setMailSent] = useState(false)

  // initiate form controllers and default values
  const {
    register,
    watch,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(newsLetterSchema),
  })

  const onSubmit = async data => {
    // get username from email
    const username = data.email?.split("@")[0]?.replace(/[^a-z\d]+/gi, "")

    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.newsletter }
      postData["name"] = username
      //Shallow clone for Google data
      postData["google_data"] = {...postData}
      postData['g-recaptcha-response'] = token;
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        setMailSent(true)
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.newsletter.event_tracking,
        form_name: FORM_CONFIG.newsletter.form_name,
      })
    }
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful && mailSent) {
      reset()
      setTimeout(() => setMailSent(false), 10000)
    }
  }, [formState, reset])

  return (
    <>
      {errors?.email && <p className="error-msg">{errors.email.message}</p>}
      {mailSent && <p className="success-msg">{SUCCESS_MSG}</p>}
      <form
        className="footer-email-form input-group"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          className="form-control"
          placeholder="Enter your email address"
          type={"email"}
          {...register("email")}
        />
        <button type="submit">
          <i className="icon icon-email-arrow"></i>
        </button>
        <Recaptcha recaptchaRef={recaptchaRef} />
        {/* <pre style={{ color: "white" }}>{JSON.stringify(watch(), null, 2)}</pre> */}
      </form>
    </>
  )
}

export default NewsletterForm
