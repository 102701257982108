export const scrollToElement = (element, offset = 1) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}

export const getWhatsAppURL = number => {
  if (!number) return null
  // remove everything except numbers
  const formatedNumber = number?.replace(/\D/g, "")

  return `https://api.whatsapp.com/send?phone=${formatedNumber}`
}

export const getWhatsAppTEXT = (props) => {
  if (!props) return null
  const pageurl = typeof window !== "undefined" ? window.location.href : "";

  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const priceFormat =
    props?.propertyData?.price && !Number.isNaN(props?.propertyData?.price)
      ? new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
      }).format(props?.propertyData?.price)
    : "";

  var preText = `Hello,%0AI would like to get more information about this property you posted on your website:%0A%0AReference: ${props?.propertyData?.extra?.referenceNumber}
  %0A${props?.propertyData?.building ? "Type: " + capitalize(props?.propertyData?.building) + "%0A"
    : ""
  }Price: ${props?.propertyData?.price ? priceFormat
    : ""
  }%0A${props?.propertyData?.extra?.community_propertyfinder
    ? "Location: " + props?.propertyData?.extra?.community_propertyfinder + " "
    : ""
  }${props?.propertyData?.extra?.sub_community_propertyfinder
    ? props?.propertyData?.extra?.sub_community_propertyfinder + "%0A%0A"
    : ""
  }Link: ${pageurl} %0A%0AAny changes made to this message will result in the enquiry not being sent to the agent.`

  return preText
}

export const sort = (a, b, sortType = 'asc') => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  if(sortType === 'asc'){
    return a - b;
  } else {
    return b - a;
  }
}

export const formTracking = ({ event_tracking, form_name }) => {
  // tracking event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "formSubmit",
    formType: "form-" + event_tracking,
    formId: "form-" + event_tracking,
    formName: form_name,
    formLabel: form_name,
  })
}
