import React from "react";
// import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

const AreaList = ({areas, removeArea, className=''}) => {
    if (!areas || areas.length < 1)
        return <></>

    function removeAreaFun(e, area) {
        e.preventDefault();
        removeArea(area)
    }
    function removeAreaAllFun(e, area) {
        e.preventDefault();
        removeArea('', true)
    }

    return (
        <div className={`area-list ${className}`}>
            <div className="section-tags-wrapper">
                <Stack direction="horizontal" className="gap-15 list-tags flex-wrap">
                    {areas.map((item, key) => {
                        return(
                            <div key={key} className="li">
                                <div className="tag">
                                    <div className="tag-text"><span>{item.name}</span></div>
                                    <a href="javascript:void(0)" className="tag-clear link-tag-clear pointer-events-all cursor-pointer ms-3" onClick={(e) => removeAreaFun(e, item.slug)}>
                                        <i className="icon icon-suggestion-close"></i>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                    <div className="li pointer-events-all pointer-events-close-all clear-btn-area">
                        <a href="javascript:void(0)" className="tag-clear link-clear-all" onClick={(e) => removeAreaAllFun(e, '')}>Clear All</a>
                    </div>
                </Stack>
            </div>
        </div>
    )
}

export default AreaList;