import { useState } from "react"
// import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Tab, Tabs } from 'react-bootstrap';
import _ from "lodash";
import { find } from "lodash";
import { navigate } from "@reach/router";
import Select from "react-select";
import { SearchOverlayOptions, customStyles, propertyTypes } from "../common/propertyUtils";
import SearchListBox from "../SearchResults/PredictiveSearch/search-multiarea";
import AreaListBox from "../SearchResults/PredictiveSearch/AreaList";
import "./Header.scss"

const SearchOverlay = (props) => {

    // Sale
    // Search overlay
    const [areaVal, setAreaVal] = useState('');
    const [residentialType, setResidentialType] = useState({ value: 'properties/for-sale/', label: 'Buy' });
    // Multi area search
    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);
    // 
    const handleChangeResidentialType = (e) => {
        setResidentialType(e)
    }
    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (_.isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearch = (e) => {
        // 
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")

        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = `/${residentialType.value}in-${areaValue}`;
        } else {
            searchFilterResults = `/${residentialType.value}in-dubai/`;
        }

        navigate(searchFilterResults);
    }
    // Submit search
    // Sale

    // Rent
    // Search overlay
    const [areaValRent, setAreaValRent] = useState('');

    // Multi area search
    const [areasArrRent, setAreasArrRent] = useState([]);
    const [areasListRent, setAreasListRent] = useState([]);
    // 

    const handleMultiAreaFunRent = (valRent) => {
        let areasRent = [];
        if (_.isEmpty(find(areasArrRent, valRent))) {
            areasRent = [...areasArrRent]
            areasRent.push(valRent)
            setAreasArrRent(areasRent)
        }
    }

    const removeAreaRent = (areaRent, clear=false) => {
        if (!clear) {
            let prevAreasRent = areasArrRent.filter( x => x.slug !== areaRent)
            setAreasArrRent(prevAreasRent);
        } else {
            setAreasArrRent([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearchRent = (e) => {
        // 
        var areaValuefetchRent = areasArrRent.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValueRent = areaValuefetchRent.filter(function (e) {return e != null;});
        var areaValueRent = removenullareaValueRent.map((items) => items).join("-and-")

        var searchFilterResultsRent = "";

        if(areaValueRent) {
            searchFilterResultsRent = "/properties/for-rent/in-" + areaValueRent + "/";
        } else {
            searchFilterResultsRent = "/properties/for-rent/in-dubai/";
        }

        navigate(searchFilterResultsRent);
    }
    // Submit search
    // Rent

    // Commercial


    // Search overlay
    const [areaValCommercial, setAreaValCommercial] = useState('');
    const [commercialType, setCommercialType] = useState({ value: 'properties/commercial/for-sale/', label: 'Buy' });

    // Multi area search
    const [areasArrCommercial, setAreasArrCommercial] = useState([]);
    const [areasListCommercial, setAreasListCommercial] = useState([]);
    // 


    const handleChangeCommercialType = (e) => {
        setCommercialType(e)
    }
    const handleMultiAreaFunCommercial = (valCommercial) => {
        let areasCommercial = [];
        if (_.isEmpty(find(areasArrCommercial, valCommercial))) {
            areasCommercial = [...areasArrCommercial]
            areasCommercial.push(valCommercial)
            setAreasArrCommercial(areasCommercial)
        }
    }

    const removeAreaCommercial = (areaCommercial, clear=false) => {
        if (!clear) {
            let prevAreas = areasArrCommercial.filter( x => x.slug !== areaCommercial)
            setAreasArrCommercial(prevAreas);
        } else {
            setAreasArrCommercial([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearchCommercial = (e) => {
        // 
        var areaValuefetchCommercial = areasArrCommercial.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValueCommercial = areaValuefetchCommercial.filter(function (e) {return e != null;});
        var areaValueCommercial = removenullareaValueCommercial.map((items) => items).join("-and-")

        var searchFilterResultsCommercial = "";

        if(areaValueCommercial) {
            searchFilterResultsCommercial = `/${commercialType.value}in-${areaValueCommercial}`;
        } else {
            searchFilterResultsCommercial = `/${commercialType.value}in-dubai/`
        }
 
       navigate(searchFilterResultsCommercial);
    }
    // Submit search
    // Commercial

    // Offplan
    // Search overlay
    const [areaValOffplan, setAreaValOffplan] = useState('');

    // Multi area search
    const [areasArrOffplan, setAreasArrOffplan] = useState([]);
    const [areasListOffplan, setAreasListOffplan] = useState([]);
    // 

    const handleMultiAreaFunOffplan = (valOffplan) => {
        let areasOffplan = [];
        if (_.isEmpty(find(areasArrOffplan, valOffplan))) {
            areasOffplan = [...areasArrOffplan]
            areasOffplan.push(valOffplan)
            setAreasArrOffplan(areasOffplan)
        }
    }

    const removeAreaOffplan = (areaOffplan, clear=false) => {
        if (!clear) {
            let prevAreas = areasArrOffplan.filter( x => x.slug !== areaOffplan)
            setAreasArrOffplan(prevAreas);
        } else {
            setAreasArrOffplan([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearchOffplan = (e) => {
        // 
        var areaValuefetchOffplan = areasArrOffplan.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValueOffplan = areaValuefetchOffplan.filter(function (e) {return e != null;});
        var areaValueOffplan = removenullareaValueOffplan.map((items) => items).join("-and-")

        var searchFilterResultsOffplan = "";

        if(areaValueOffplan) {
            searchFilterResultsOffplan = "/off-plan-properties/for-sale/in-" + areaValueOffplan + "/";
        } else {
            searchFilterResultsOffplan = "/off-plan-properties/for-sale/in-dubai/";
        }

        navigate(searchFilterResultsOffplan);
    }
    // Submit search
    // Offplan

    // Holiday lets
    // Search overlay
    const [areaValHolidaylets, setAreaValHolidaylets] = useState('');

    // Multi area search
    const [areasArrHolidaylets, setAreasArrHolidaylets] = useState([]);
    const [areasListHolidaylets, setAreasListHolidaylets] = useState([]);
    // 

    const handleMultiAreaFunHolidaylets = (valHolidaylets) => {
        let areasHolidaylets = [];
        if (_.isEmpty(find(areasArrHolidaylets, valHolidaylets))) {
            areasHolidaylets = [...areasArrHolidaylets]
            areasHolidaylets.push(valHolidaylets)
            setAreasArrHolidaylets(areasHolidaylets)
        }
    }

    const removeAreaHolidaylets = (areaHolidaylets, clear=false) => {
        if (!clear) {
            let prevAreas = areasArrHolidaylets.filter( x => x.slug !== areaHolidaylets)
            setAreasArrHolidaylets(prevAreas);
        } else {
            setAreasArrHolidaylets([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearchHolidaylets = (e) => {
        // 
        var areaValuefetchHolidaylets = areasArrHolidaylets.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValueHolidaylets = areaValuefetchHolidaylets.filter(function (e) {return e != null;});
        var areaValueHolidaylets = removenullareaValueHolidaylets.map((items) => items).join("-and-")

        var searchFilterResultsHolidaylets = "";

        if(areaValueHolidaylets) {
            searchFilterResultsHolidaylets = "/holiday-homes/for-rent/in-" + areaValueHolidaylets + "/";
        } else {
            searchFilterResultsHolidaylets = "/holiday-homes/for-rent/in-dubai/";
        }

        navigate(searchFilterResultsHolidaylets);
    }
    // Submit search
    // Holiday lets

    // Search mobile
    const [searchValOption, setSearchValOption] = useState('buy');

    // Search overlay
    const [areaValSearchVal, setAreaValSearchVal] = useState('');

    // Multi area search
    const [areasArrSearchVal, setAreasArrSearchVal] = useState([]);
    const [areasListSearchVal, setAreasListSearchVal] = useState([]);
    // 

    const handleMultiAreaFunSearchVal = (valSearchVal) => {
        let areasSearchVal = [];
        if (_.isEmpty(find(areasArrSearchVal, valSearchVal))) {
            areasSearchVal = [...areasArrSearchVal]
            areasSearchVal.push(valSearchVal)
            setAreasArrSearchVal(areasSearchVal)
        }
    }

    const removeAreaSearchVal = (areaSearchVal, clear=false) => {
        if (!clear) {
            let prevAreas = areasArrSearchVal.filter( x => x.slug !== areaSearchVal)
            setAreasArrSearchVal(prevAreas);
        } else {
            setAreasArrSearchVal([]);
        }
    }
    // Multi area search
    // Search overlay

    // Submit search
    const submitSearchSearchVal = (e) => {
        // 
        var areaValuefetchSearchVal = areasArrSearchVal.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValueSearchVal = areaValuefetchSearchVal.filter(function (e) {return e != null;});
        var areaValueSearchVal = removenullareaValueSearchVal.map((items) => items).join("-and-")

        var searchFilterResultsSearchVal = "";

        if(areaValueSearchVal) {
            if(searchValOption === "buy") {
                searchFilterResultsSearchVal = "/properties/for-sale/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "rent") {
                searchFilterResultsSearchVal = "/properties/for-rent/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "commercial_buy") {
                searchFilterResultsSearchVal = "/properties/commercial/for-sale/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "commercial_rent") {
                searchFilterResultsSearchVal = "/properties/commercial/for-rent/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "offplan") {
                searchFilterResultsSearchVal = "/off-plan-properties/for-sale/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "holidaylets") {
                searchFilterResultsSearchVal = "/holiday-homes/for-rent/in-" + areaValueSearchVal + "/";
            }
        } else {
            if(searchValOption === "buy") {
                searchFilterResultsSearchVal = "/properties/for-sale/in-dubai/";
            } else if(searchValOption === "rent") {
                searchFilterResultsSearchVal = "/properties/for-rent/in-dubai/";
            } else if(searchValOption === "commercial_buy") {
                searchFilterResultsSearchVal = "/properties/commercial/for-sale/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "commercial_rent") {
                searchFilterResultsSearchVal = "/properties/commercial/for-rent/in-" + areaValueSearchVal + "/";
            } else if(searchValOption === "offplan") {
                searchFilterResultsSearchVal = "/off-plan-properties/for-sale/in-dubai/";
            } else if(searchValOption === "holidaylets") {
                searchFilterResultsSearchVal = "/holiday-homes/for-rent/in-dubai/";
            }
        }

        navigate(searchFilterResultsSearchVal);
    }
    // Submit search
    // Search mobile

    return (
        <>
            <div className="d-md-none">
                <div className="search-overlay-form-mobile">
                    <Select
                        options={SearchOverlayOptions}
                        isSearchable={false}
                        placeholder={"Buy"}
                        className={"select-control"}
                        classNamePrefix={"react-select"}
                        styles={customStyles}
                        value={SearchOverlayOptions.find(obj => obj.value === searchValOption)}
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={e => setSearchValOption(e.value)}
                    />
                </div>
                <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                    <div className="predictive-search-box">
                        <SearchListBox 
                            areaVal={areaValSearchVal} 
                            placeholder={searchValOption === "offplan" ? "Location, developer or project" : "City, community or area"} 
                            setAreaVal={setAreaValSearchVal}
                            setAreasList={setAreasListSearchVal} 
                            handleMultiAreaFun={handleMultiAreaFunSearchVal} 
                            autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                        />
                    </div>
                </div>
                <div className="search-btn-wrapper">
                    <button className="search-btn" onClick={() => {submitSearchSearchVal(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex">Search</span></button>
                </div>
                <div className="predictive-arelist-suggestion">
                    <AreaListBox areas={areasArrSearchVal} removeArea={removeAreaSearchVal} />
                </div>
            </div>

            <div className="d-md-block d-none">
                <Tabs
                    defaultActiveKey="residential"
                    id="uncontrolled-tab-example"
                    className="search-overlay-tab"
                >
                    <Tab eventKey="residential" title="Residential">
                        <div className="search-overlay-form">
                            <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                                <Select
                                    options={[
                                        { value: 'properties/for-sale/', label: 'Buy' },
                                        { value: 'properties/for-rent/', label: 'Rent' }
                                    ]}
                                    isSearchable={false} 
                                    value={residentialType}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={customStyles}
                                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                    onChange={(e) => handleChangeResidentialType(e)}
                                />
                                <div className="predictive-search-box">
                                <SearchListBox 
                                    areaVal={areaVal} 
                                    placeholder={"City, community or area"} 
                                    setAreaVal={setAreaVal}
                                    setAreasList={setAreasList} 
                                    handleMultiAreaFun={handleMultiAreaFun} 
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                />
                                </div>
                                <div className="search-btn-wrapper d-md-block d-none">
                                    <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                </div>
                            </div>
                            {/* <div className="search-btn-wrapper d-xl-none">
                                <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                            </div> */}
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArr} removeArea={removeArea} />
                            </div>
                        </div>
                    </Tab>
                    {/* <Tab eventKey="rent" title="Rent">
                        <div className="search-overlay-form">
                            <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                                <div className="predictive-search-box">
                                <SearchListBox 
                                    areaVal={areaValRent} 
                                    placeholder={"City, community or area"} 
                                    setAreaVal={setAreaValRent}
                                    setAreasList={setAreasListRent} 
                                    handleMultiAreaFun={handleMultiAreaFunRent} 
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                />
                                </div>
                                <div className="search-btn-wrapper d-md-block d-none">
                                    <button className="search-btn" onClick={() => {submitSearchRent(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                </div>
                            </div>
                            {/* <div className="search-btn-wrapper d-xl-none">
                                <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                            </div>  
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArrRent} removeArea={removeAreaRent} />
                            </div>
                        </div>
                    </Tab> */}
                    <Tab eventKey="commercial" title="commercial" className="commercial">  
                        <div className="search-overlay-form">
                            <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                                <Select
                                    options={[
                                        { value: 'properties/commercial/for-sale/', label: 'Buy' },
                                        { value: 'properties/commercial/for-rent/', label: 'Rent' }
                                    ]}
                                    isSearchable={false} 
                                    value={commercialType}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={customStyles}
                                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                    onChange={(e) => handleChangeCommercialType(e)}
                                />
                                <div className="predictive-search-box">
                                <SearchListBox 
                                    areaVal={areaValCommercial} 
                                    placeholder={"City, community or area"} 
                                    setAreaVal={setAreaValCommercial}
                                    setAreasList={setAreasListCommercial} 
                                    handleMultiAreaFun={handleMultiAreaFunCommercial} 
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                />
                                </div>
                                <div className="search-btn-wrapper d-md-block d-none">
                                    <button className="search-btn" onClick={() => {submitSearchCommercial(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                </div>
                            </div>
                            {/* <div className="search-btn-wrapper d-xl-none">
                                <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                            </div> */}
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArrCommercial} removeArea={removeAreaCommercial} />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="offplan" title="Off plan">
                        <div className="search-overlay-form">
                            <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                                <div className="predictive-search-box">
                                <SearchListBox 
                                    areaVal={areaValOffplan} 
                                    placeholder={"Location, developer or project"} 
                                    setAreaVal={setAreaValOffplan}
                                    setAreasList={setAreasListOffplan} 
                                    handleMultiAreaFun={handleMultiAreaFunOffplan} 
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                />
                                </div>
                                <div className="search-btn-wrapper d-md-block d-none">
                                    <button className="search-btn" onClick={() => {submitSearchOffplan(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                </div>
                            </div>
                            {/* <div className="search-btn-wrapper d-xl-none">
                                <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                            </div> */}
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArrOffplan} removeArea={removeAreaOffplan} />
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="holidaylets" title="Holiday Lets">
                        <div className="search-overlay-form">
                            <div className="search-overlay-form-wrapper d-flex align-items-center justify-content-between">
                                <div className="predictive-search-box">
                                <SearchListBox 
                                    areaVal={areaValHolidaylets} 
                                    placeholder={"City, community or area"} 
                                    setAreaVal={setAreaValHolidaylets}
                                    setAreasList={setAreasListHolidaylets} 
                                    handleMultiAreaFun={handleMultiAreaFunHolidaylets} 
                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                />
                                </div>
                                <div className="search-btn-wrapper d-md-block d-none">
                                    <button className="search-btn" onClick={() => {submitSearchHolidaylets(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                </div>
                            </div>
                            {/* <div className="search-btn-wrapper d-xl-none">
                                <button className="search-btn" onClick={() => {submitSearch(); props.showOverlay();}}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                            </div> */}
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArrHolidaylets} removeArea={removeAreaHolidaylets} />
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default SearchOverlay