const FORM_CONFIG = {
  enquiry: {
    form_name: "Contact/Get in touch/Email",
    form_type:"contact",
    email_temp_user: "contact_user",
    email_temp_admin: "contact_admin",
    email_subject_user: "Thank you for your enquiry",
    email_subject_admin: "From Web: Contact/Get in touch/Email",
    event_tracking: "contact",
    page_url: "/contact/general-enquiry/",
  },
  downloadPayment: {
    form_name: "Download payment plan",
    form_type:"download_payment_plan",
    email_temp_user: "payment_user",
    email_temp_admin: "payment_admin",
    email_subject_user: "Download payment plan",
    email_subject_admin: "From Web: Download payment plan",
    event_tracking: "contact",
    page_url: "/",
  },
  newsletter: {
    form_name: "Newsletter subscription",
    form_type:"newsletter",
    email_temp_user: "newsletter_user",
    email_temp_admin: "newsletter_admin",
    email_subject_user: "Thank you for subscribing to our newsletter",
    email_subject_admin: "From Web: Newsletter subscription",
    event_tracking: "contact",
    page_url: "/",
  },
  book_valuation: {
    form_name: "Book a valuation/List a property",
    form_type:"valuation",
    email_temp_user: "valuation_user",
    email_temp_admin: "valuation_admin",
    email_subject_user: "Book a valuation",
    email_subject_admin: "From Web: Book a valuation/List a property",
    event_tracking: "valuation",
    page_url: "/contact/list-a-property/",
  },
  book_viewing: {
    form_name: "Book a viewing (Email Agent)/Make an enquiry",
    form_type:"viewing",
    email_temp_user: "viewing_user",
    email_temp_admin: "viewing_admin",
    email_subject_user: "Book a viewing",
    email_subject_admin: "From Web: Book a viewing",
    event_tracking: "viewing",
    page_url: "/book-a-viewing/",
  },
  careers: {
    form_name: "Careers",
    form_type:"careers",
    email_temp_user: "careers_user",
    email_temp_admin: "careers_admin",
    email_subject_user: "Careers",
    email_subject_admin: "From Web: Careers",
    email_server_func: "careers",
    event_tracking: "careers",
    page_url: "/careers/job-listing",
  },
  make_a_complaint: {
    form_name: "Make A Complaint",
    form_type:"complaint",
    email_temp_user: "make_complaint_user",
    email_temp_admin: "make_complaint_admin",
    email_subject_user: "Make A Complaint",
    email_subject_admin: "From Web: Make A Complaint",
    event_tracking: "complaint",
    page_url: "/contact/make-a-complaint/",
  },
  register_interest: {
    form_name: "Register Interest",
    form_type:"register",
    email_temp_user: "register_interest_user",
    email_temp_admin: "register_interest_admin",
    email_subject_user: "Thank you for your interest",
    email_subject_admin: "From Web: Register a Interest",
    event_tracking: "register",
    page_url: "/register-interest/",
  },
  make_enquiry: {
    form_name: "Make an Enquiry",
    form_type:"enquiry",
    email_temp_user: "make_enquiry_user",
    email_temp_admin: "make_enquiry_admin",
    email_subject_user: "Thank you for your enquiry",
    email_subject_admin: "From Web: Make an Enquiry",
    event_tracking: "enquiry",
    page_url: "/register-interest/",
  },
}

export const SUCCESS_MSG =
  "Thank you for contacting us. A member of our team will contact you shortly."
export const SUCCESS_MSG_CAREER =
  "Thank you for your interest. We will be in touch with you shortly."

export default FORM_CONFIG
