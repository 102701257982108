import React, { useEffect } from "react"
import $ from "jquery"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import "./Header.scss"

const MenuData = () => {

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                burgerMenus(sort: "Sort:asc") {
                label
                custom_link
                menu {
                    slug
                    id
                    parent {
                    slug
                    parent {
                        slug
                    }
                    }
                }
                add_menu {
                    label
                    custom_link
                    menu {
                    id
                    slug
                    parent {
                        slug
                        parent {
                        slug
                        }
                    }
                    }
                }
                }
            }
        }
    `)

    const allmenus = data.glstrapi.burgerMenus;

    useEffect(() => {
        $(".navigation li a").click(function () {
          $(window).scrollTop(0)
        })
        $(".navigation .menu-accordian .submenu-list").each(function (i, el) {
          // Remove white space
          var html = $(el).html().replace(/\s+/, "")
          // Check if element is :empty or length of html is zero
          if ($(el).is(":empty") || html.length === 0) {
            $(el).remove()
          } else {
            $(el).parent().addClass("has-submenu")
          }
        })
    
        $(".card-header.has-submenu .firstleveltrigger")
          .unbind()
          .on("click", function (e) {
            var checkactive = $(this).parent().attr("class")
            if (checkactive.indexOf("active") !== -1) {
              $(".card-header.has-submenu").removeClass("active")
              $(".submenu-list").stop(true, true).slideUp()
              $(".has-submenu .firstleveltrigger i").removeClass("icon icon-menu-minus")
              $(".has-submenu .firstleveltrigger i").addClass("icon icon-menu-plus")
            } else {
              $(".card-header.has-submenu").removeClass("active")
              $(".submenu-list").slideUp()
              $(".has-submenu .firstleveltrigger i").removeClass("icon icon-menu-minus")
              $(".has-submenu .firstleveltrigger i").addClass("icon icon-menu-plus")
              $(this).parent().addClass("active")
              $(this).parent().find(".submenu-list").stop(true, true).slideDown()
              $(this).parent().find(".firstleveltrigger i").removeClass("icon icon-menu-plus")
              $(this).parent().find(".firstleveltrigger i").addClass("icon icon-menu-minus")
            }
          })
    }, [])

    return (
        <div className="navigation">
            <div className="menu-accordian">
            {allmenus &&
                allmenus.map((item, index) => {
                    const href =
                        item.custom_link === "javascript:void(0)"
                        ? { href: item.custom_link }
                        : {
                            to: GetPageURL(
                                item.menu,
                                item.custom_link
                            ),
                            }
                return (
                    <li className={"card-header card-header-parent"}>
                    <Link className="firstleveltrigger" {...href} >
                        {item.label}
                    </Link>
                    <div className="firstleveltrigger">
                        {item.add_menu && item.add_menu.length > 0 && (
                            <i className="icon icon-menu-plus"></i>
                        )}
                    </div>
                    <ul className="submenu-list">
                        {item.add_menu &&
                        item.add_menu.length > 0 &&
                        item.add_menu.map(submenu => {

                            return (
                            <li className="card-header card-header-child secondlevel-menu-item">
                                <Link
                                to={GetPageURL(submenu.menu, submenu.custom_link)}
                                >
                                {submenu.label}
                                </Link>
                            </li>
                            )
                        })}
                    </ul>
                    </li>
                )
                })}
            {/* <div className="nav-actions d-flex align-items-center">
                <Link className="btn btn-black" to="/valuation/"> list your property </Link>
            </div> */}
            {/* <div className="burger-ct d-flex align-items-center">
                <i className="icon-phone"></i>Prefer the phone instead? Call{" "}
                <a href="tel:+971 58346 7535">+971 58346 7535</a>
            </div> */}
            </div>
        </div>
    )
}

export default MenuData