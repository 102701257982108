export const aboutPageURL = "/about-us/"
export const teamsPageURL = "/meet-the-team/"
export const newsPageURL = aboutPageURL + "latest-property-news/"
export const areaguidesPageURL = aboutPageURL + "dubai-communities/"
export const termsURL = "/terms-and-conditions/"
export const privacyURL = "/privacy-policy/"
export const contactPageURL = "/contact/general-enquiry/"
export const listPropertyURL = "/contact/list-a-property/"
export const bookViewingURL = "/book-a-viewing"
export const teamEnquiryURL = "/contact/team-enquiry/"
export const makeAComplaintURL = "/contact/make-a-complaint/"
export const registerInterestURL = "/register-interest/"
export const makeEnquiryURL = "/make-an-enquiry/"
export const propertySaleResultsURL = "/properties/for-sale/"
export const propertyRentResultsURL = "/properties/for-rent/"
export const propertyCommercialSaleResultsURL = "/properties/commercial/for-sale/"
export const propertyCommercialRentResultsURL = "/properties/commercial/for-rent/"
export const offplanSaleResultsURL = "/off-plan-properties/for-sale/"
export const offplanRentResultsURL = "/off-plan-properties/for-rent/"
export const HolidayHomesResultsURL = "/holiday-homes/for-rent/"
export const videosPageURL = aboutPageURL + "property-videos/"
