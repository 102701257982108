import React from "react"
import { isEmpty, forEach } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import * as qs from "query-string"
/** Get the menu page url */
export const GetPageURL = (data = null, manualLink = null) => {
  var currentURL = "/"
  if (!isEmpty(data)) {
    currentURL = `/${data?.slug}`
    if (data.slug === "home" || data.slug === null) {
      currentURL = "/"
    }
    if (!isEmpty(data.parent)) {
      if (isNotEmpty(data?.parent?.parent?.parent)) {
        return `/${data.parent?.parent?.parent?.slug}/${data.parent?.parent?.slug}/${data.parent?.slug}${currentURL}/`
      } else if (data?.parent?.parent !== null) {
        return `/${data.parent?.parent?.slug}/${data.parent?.slug}${currentURL}/`
      }
      return `/${data.parent.slug}${currentURL}/`
    } else {
      if (endsWith(currentURL, "/")) {
        return `${currentURL}`
      } else {
        return `${currentURL}/`
      }
    }
  }

  if (!isEmpty(manualLink)) {
    if (startsWith(manualLink, "/")) {
      return manualLink
    }
    return `/${manualLink}`
  }

  return currentURL
}

/**Start with */
export const startsWith = (str, word) => {
  return str?.lastIndexOf(word, 0) === 0
}
// Altname
export const AltSiteName = " - White and Co"
/**Eng with */
export const endsWith = (str, word) => {
  return str?.slice(-1) === word
}
export const isNotEmpty = val => {
  if (typeof val != "undefined" && val !== null && val !== "") {
    return true
  }
  return false
}

/* Page data to build breadcumbs */
export const PageBreadcrumbs = (slug, staticList = []) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        menus {
          slug
          label
          parent {
            slug
            label
            parent {
              slug
              label
              parent {
                slug
                label
              }
            }
          }
          page {
            id
          }
        }
      }
    }
  `)

  var PageURL = data.glstrapi.menus?.filter(item => item.slug == slug)?.pop()

  var breadcrumbs = []
  if (!isEmpty(PageURL)) {
    // if (!isEmpty(PageURL?.parent?.parent?.parent)) {
    //   breadcrumbs.push({ label: PageURL?.parent?.parent?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}` })
    //   breadcrumbs.push({ label: PageURL?.parent?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}/${PageURL?.parent?.parent?.slug}` })
    //   breadcrumbs.push({ label: PageURL?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}/${PageURL?.parent?.parent?.slug}/${PageURL?.parent?.slug}` })
    // }
    if (!isEmpty(PageURL?.parent?.parent)) {
      breadcrumbs.push({
        label: PageURL?.parent?.parent?.label,
        url: `/${PageURL?.parent?.parent?.slug}/`,
      })
      breadcrumbs.push({
        label: PageURL?.parent?.label,
        url: `/${PageURL?.parent?.parent?.slug}/${PageURL?.parent?.slug}/`,
      })
    } else if (!isEmpty(PageURL?.parent)) {
      breadcrumbs.push({
        label: PageURL?.parent?.label,
        url: `/${PageURL?.parent?.slug}/`,
      })
      // breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}/${PageURL?.parent?.slug}` })
    }
    //  else {
    //   breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}` })
    // }
  }
  if (!isEmpty(staticList)) {
    staticList.map(item =>
      breadcrumbs.push({ label: item.Title, url: `/${item.slug}/` })
    )
  }
  if (!isEmpty(PageURL?.label) && isEmpty(staticList)) {
    breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}/` })
  }
  // console.log(breadcrumbs)
  return breadcrumbs
}

/**Send Mail*/
export const SendMail = postData => {
  return window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(postData),
  })
}

/**Form Validation*/
export const FormValidation = (fields, validationFields) => {
  var error = {}
  var isValid = true
  forEach(validationFields, (field, k) => {
    if (fields[field] === null || fields[field] === "") {
      error[field] = true
      isValid = false
    } else {
      if (
        field === "email" &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          fields[field]
        )
      ) {
        error[field] = true
        isValid = false
      } else if (field === "telephone" && fields[field].length <= 8) {
        error[field] = true
        isValid = false
      } else {
        error[field] = false
      }
    }
  })
  if (!isValid) {
    return { status: false, errors: error }
  }
  return { status: true, errors: {} }
}
