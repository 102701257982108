import React, {useState,useEffect } from "react"
import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import "./assets/styles/_index.scss"
import sbLogo from "../../images/icons/sb-logo.svg"
import NewsletterForm from "../forms/NewsletterForm"
import { privacyURL, termsURL } from "../../site/urls"
import { Helmet } from "react-helmet"
import CookieConsent from "react-cookie-consent";
import $ from "jquery"
import StaticPopularSearch from "../StaticPopularSearch/StaticPopularSearch"


const Footer = ({ layout,categoryType }) => {
  const [showPopular, setShowPopular]=useState(false)
  const data = useStaticQuery(graphql`
    query GetFooterData {
      glstrapi {
        socialLink {
          footer_menu {
            id
            title
            footer_links {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                label
                link_type
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
                id
              }
            }
          }
        }
        siteConfig {
          facebook_link
          twitter_link
          linkedin_link
          instagram_link
          Footer_Logo {
            url
          }
        }
         propertySearch {
          category1
          category2
          category3
          category4
        }
      }
    }
  `)

  // Cookie Trigger
  useEffect(() => {
    setTimeout(function(){
      if ($('.cookie-popup .CookieConsent').length > 0) { 
        $('.footer-wrapper').addClass('with_cookie-popup');
      } 
      $('.cookieok').click(function(event) {
        $('.acceptcookie').click();
      }); 
    }, 500);
  }, [])
//  

  const footerMenuData = data.glstrapi.socialLink.footer_menu
  const footerSocialData = data.glstrapi.siteConfig

  const showBugherd = process.env.GATSBY_SHOW_BUGHERD

  // Current year
  const currentYear = new Date().getFullYear()
  // Current year

  const popularsearchData = data.glstrapi.propertySearch


  return (
    <footer className={`footer-wrapper ${layout === "property-details" ? "property-details" : ""}`}>
      {showBugherd == "true" ? (
        <Helmet>
          <script
            type="text/javascript"
            src="https://www.bugherd.com/sidebarv2.js?apikey=ofnlpioj9bqexnp5lbzjew"
            async="true"
          ></script>
        </Helmet>
      ) : (
        ""
      )}
      <Container>
        <Row>
          {categoryType && (
            <div className="popular-search-component">
              <div className="popular-search-section">
              <h4>Popular Search</h4>
              <div className="chevron-icon" onClick={()=>setShowPopular(!showPopular)}><i className={!showPopular?"icon chevron-up down":"icon chevron-up"}></i></div>
              </div>
              {categoryType === "category1" && (
                <StaticPopularSearch category={popularsearchData.category1} showPopular={showPopular}/>
              )}
              {categoryType === "category2" && (
                <StaticPopularSearch category={popularsearchData.category2} showPopular={showPopular} />
              )}
              {categoryType === "category3" && (
                <StaticPopularSearch category={popularsearchData.category3} showPopular={showPopular}/>
              )}
              {categoryType === "category4" && (
                <StaticPopularSearch category={popularsearchData.category4} showPopular={showPopular}/>
              )}
            </div>
          )}
          <Col lg={7}>
            <div className="footer-stay">
              <h3>Stay up to date with us</h3>
              <NewsletterForm />
              <div className="footer-email-terms">
                By submitting the form, you agree to our{" "}
                <Link to={termsURL}>Terms & Conditions</Link> and{" "}
                <Link to={privacyURL}>Privacy Policy</Link>.
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {footerMenuData.length > 0 &&
            footerMenuData.map(menu => (
              <Col xl={3} lg={4}>
                <div className="footer-menu">
                  <div className="footer-menu-title">{menu.title}</div>
                  <ul className="footer-menu-list list-unstyled">
                    {menu.footer_links?.length > 0 &&
                      menu.footer_links.map(link => (
                        <li>
                          <Link
                            target={
                              link?.target === "new_window" ? "_blank" : ""
                            }
                            to={GetPageURL(link.menu_item, link.custom_link)}
                            className="footer-menu-list-item"
                          >
                            {link.label}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            ))}
          <Col xl={3}>
            <div className="footer-inner footer-social-menu">
              <ul className="list-inline social-menu-list">
                {footerSocialData.facebook_link && (
                  <li className="list-inline-item">
                    <a target={"_blank"} href={footerSocialData.facebook_link}>
                      <i className="icon icon-footer-fb"></i>
                    </a>
                  </li>
                )}
                {footerSocialData.twitter_link && (
                  <li className="list-inline-item">
                    <a target={"_blank"} href={footerSocialData.twitter_link}>
                      <i className="icon icon-footer-twitter"></i>
                    </a>
                  </li>
                )}
                {footerSocialData.instagram_link && (
                  <li className="list-inline-item">
                    <a target={"_blank"} href={footerSocialData.instagram_link}>
                      <i className="icon icon-footer-insta"></i>
                    </a>
                  </li>
                )}
                {footerSocialData.linkedin_link && (
                  <li className="list-inline-item">
                    <a target={"_blank"} href={footerSocialData.linkedin_link}>
                      <i className="icon icon-footer-linkedin"></i>
                    </a>
                  </li>
                )}
              </ul>
              <p className="footer-address">White & Co Real Estate LLC<br/> 7th & 20th floor,
                  Control Tower,<br/> Motor City, Dubai, UAE.
              </p>
              <p className="footer-copyright">
                © {currentYear}. All Rights Reserved
              </p>
              <div className="footer-siteby d-flex align-items-center">
                Site by
                <a href="https://starberry.tv/" className="d-flex" target={"_blank"}>
                  <img src={sbLogo} alt="Starberry Logo" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <section className="cookie-popup">
        <CookieConsent
              location="bottom"
              buttonText="Accept Cookies"
              cookieName="CookiePolicy"
              buttonClasses="acceptcookie"
              overlay={false}
              // acceptOnScrollPercentage={1}
              //onAccept={()=> ClosePopup()}
          >
              <div className="topbar-block">

                  <p>
                  We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to='/cookie-policy/'>Cookie Policy</Link>. <a href="javascript:void(0);" class="cookieok">Accept Cookies</a>
                  </p>
                  <i className="icon-close-dark"></i>
              </div>
          </CookieConsent>
      </section>
    </footer>
  )
}

export default Footer
