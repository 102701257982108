import { z } from "zod"

const ERROR = {
  name: "Please enter a valid name",
  email: "Please enter a valid email address",
  telephone: "Please enter a valid phone number",
  message: "Please enter a valid message",
  property_address: "Please enter a valid address",
  date: "Please enter a valid date",
  time: "Please enter a valid time",
  age: "Please enter your age",
  nationality: "Please enter your nationality",
  location: "Please enter your location",
}

const SCHEMA = {
  name: z.string({ message: ERROR.name }).min(1, { message: ERROR.name }),
  email: z.string().email({ message: ERROR.email }),
  telephone: z
    .number({ message: ERROR.telephone, invalid_type_error: ERROR.telephone })
    .int({ message: ERROR.telephone })
    .min(4, { message: ERROR.telephone }),
  message: z.string({ message: ERROR.message }),
  property_address: z
    .string({ message: ERROR.property_address })
    .min(1, { message: ERROR.property_address }),
  date: z.string({ message: ERROR.date }),
  time: z.string({ message: ERROR.time }),
  attachment: z
    .any()
    .refine(files => files?.length > 0, "Please select a file.")
    .refine(files => files?.[0]?.size <= 5000000, `Max file size is 5MB.`),
  attachphoto: z
    .any()
    .refine(files => files?.length > 0, "Please select a photo.")
    .refine(files => files?.[0]?.size <= 5000000, `Max photo size is 5MB.`),
  age: z
    .number({ message: ERROR.age, invalid_type_error: ERROR.age })
    .int({ message: ERROR.age })
    .min(2, { message: ERROR.age }),
  nationality: z.string({ message: ERROR.nationality }).min(1, { message: ERROR.nationality }),
  location: z.string({ message: ERROR.location }).min(1, { message: ERROR.location }),
  // .refine(
  //   files => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
  //   ".jpg, .jpeg, .png and .webp files are accepted."
  // ),
}

export const newsLetterSchema = z.object({
  email: SCHEMA.email,
})

export const newsLetterPopupSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
})

export const generalEnquirySchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
})

export const downloadPaymentSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
})

export const careersSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  attachment: SCHEMA.attachment,
  attachphoto: SCHEMA.attachphoto,
  age: SCHEMA.age,
  nationality: SCHEMA.nationality,
  location: SCHEMA.location,
  message: SCHEMA.message,
})

export const bookValuationSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  property_address: SCHEMA.property_address,
  date: SCHEMA.date,
  time: SCHEMA.time,
})

export const bookViewingSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
  date: SCHEMA.date,
  time: SCHEMA.time,
})

export const makeAComplaintSchema = z.object({
  fname: SCHEMA.name,
  lname: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  nationality: SCHEMA.nationality
})

export const registerInterestSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message
})
export const makeAnEnquirySchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message
})