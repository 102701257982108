import parse from "html-react-parser"
import React from "react"
import "./StaticPopularSearch.scss"

const StaticPopularSearch = ({ category, showPopular }) => {
  return (
    <>
      {showPopular && (
        <div className={`popular-search-list`}>{parse(category)}</div>
      )}
    </>
  )
}

export default StaticPopularSearch
